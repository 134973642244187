<div class="container">
    <div class="flex header">
        <div class="header_cell content_left">
            <app-icon (click)="openSidebar(menu_type)" [icon_name]="menu_type"></app-icon>
        </div>
        <div class="header_cell content_center">
            <img src="./../../../assets/images/Logo.svg" />
        </div>
        <div class="header_cell content_right">
            <div *ngIf="outlet_list?.length" class="search__and__select" clickOutside (clickOutside)="toggleSelectList('outside')">
                <span class="caret"></span>
                <input type="text" class="custome__input" [(ngModel)]="selected_outlet.name_location" placeholder="Choose outlet" readonly (click)="toggleSelectList('inside')" />
                <div class="custom__drop__down" *ngIf="showSelectList">
                    <input class="custom_drop_down_search" type="text" autocomplete="off" placeholder="Search" [formControl]="searchOption" />
                    <div class="drop__down__option">
                        <div
                            *ngFor="let outlet of outlet_list | searchPipe : searchOption.value : ['name']"
                            class="drop__down__list"
                            [class.drop__down__selected]="selected_outlet.authentication_token === outlet.authentication_token"
                            (click)="selectOption(outlet)"
                        >
                            {{ outlet?.name }}
                        </div>
                    </div>
                </div>
            </div>
            <img class="user" [src]="merchant_info?.logo" onError="this.src='https://hashtagloyalty.s3-ap-southeast-1.amazonaws.com/WebsiteAssets/defaultBrandLogo.png';" />
        </div>
    </div>
    <div class="side_nav" [ngClass]="{active_bar: menu_type === 'cancel'}">
        <div class="menu_item" [ngClass]="{visible: menu_type === 'cancel'}">
            <ul style="list-style-type: none">
                <li class="link merchant_name">{{ merchant_info?.name }}, {{ merchant_info?.location }}</li>
                <li class="link" (click)="navigateTo(section.link)" *ngFor="let section of sidebar_list" [class.active]="active_link === section.link">
                    {{ section.name }}
                </li>
            </ul>
            <div class="logout link" (click)="logout()">Logout</div>
            <div id="sidebar-banner">
                <div class="db-banner" (click)="openPopupForDownloadApp()">
                    <div>
                        <img src="https://cdn.hashtagloyalty.com/order-dashboard-app.png" />
                    </div>
                    <div>
                        <h2>Now get order updates on your phone!</h2>
                        <p>Get the all new <b>Order Dashboard App</b>.</p>
                    </div>
                    <img src="https://cdn.hashtagloyalty.com/order-dashboard-frontend/getongoogleplay.svg" />
                </div>
            </div>
        </div>
    </div>
    <div class="container__outer" (click)="closeSidebar()">
        <app-notification [is_open]="show_notification" clickOutside (clickOutside)="toggleNotification($event)"></app-notification>
        <div id="top-banner" *ngIf="merchant_info?.show_banner && showBanner">
            <div class="cross-btn" (click)="closeBanner()">
                <app-icon [icon_name]="'cancel_notification'"></app-icon>
            </div>
            <div class="db-banner" (click)="openPopupForDownloadApp()">
                <div>
                    <img src="https://cdn.hashtagloyalty.com/order-dashboard-app.png" />
                </div>
                <div>
                    <h2>Now get order updates on your phone!</h2>
                    <p>Track new orders, handle order updates, manage food inventory, and control store availability with the all new <b>Order Dashboard App</b>.</p>
                </div>
                <img src="https://cdn.hashtagloyalty.com/order-dashboard-frontend/getongoogleplay.svg" />
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
<!-- new order alert -->
<jw-modal [details]="{heading: 'Order Alert!', body_styles: main_modal_styles}" id="newOrderAlert" (onClick)="closeAlertModal($event, 'newOrderAlert')">
    <div class="line"></div>
    <div class="text__center">
        <div style="margin-top: 20px">{{ new_notification?.heading }}</div>
        <div style="margin-top: 10px; margin-bottom: 20px">
            {{ new_notification?.content }}
        </div>
        <div>
            <button class="btn btn__action btn--success" (click)="closeAlertModal($event, 'newOrderAlert')">OK</button>
        </div>
    </div>
</jw-modal>
<!-- enable sound -->
<jw-modal [details]="{heading: 'Enable notification sound', body_styles: main_modal_styles}" id="enableSoundAlert" (onClick)="enableSound($event)">
    <div class="line"></div>
    <div class="text__center">
        <p>Please give permission for a notification sound to play when a new order comes in.</p>
        <div>
            <button class="btn btn__action btn--success" (click)="enableSound($event)">Enable sound</button>
        </div>
    </div>
</jw-modal>
<!-- Order DB App Dialog -->
<jw-modal [details]="{heading: '', body_styles: {width: '60vw', background: '#fff'}}" id="orderAppPopup" (onClick)="closeAlertModal($event, 'orderAppPopup')">
    <div class="dialog-body" id="download-app">
        <div class="qr-body">
            <h2>Now get order updates on your phone!</h2>
            <p>Download <b>Thrive Order Dashboard</b> app now:</p>
            <h6>Scan the QR Code</h6>
            <img src="assets/images/qr-code.png" class="qr-code" alt="qr" />
            <p class="or"><span>OR</span></p>
            <a href="https://play.google.com/store/apps/details?id=com.hashtagloyalty.staff&hl=en_IN&gl=US" target="_blank">
                <img src="https://cdn.hashtagloyalty.com/order-dashboard-frontend/getongoogleplay.svg" style="width: 100% !important" />
            </a>
        </div>
        <img src="https://cdn.hashtagloyalty.com/order-dashboard-app.png" />
    </div>
</jw-modal>
