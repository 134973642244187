import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../../environments/environment';
import { DataService } from '../services/data-sharing.service';
export enum MixpanelEvents {
  login = 'Login',
  logout = 'Logout',
  page_view = 'Page View',
  order_acceptance = 'Order Acceptance',
  select_preparation_time = 'Select Preparation time',
  order_cancellation = 'Order Cancellation',
  add_manual_order = 'Add Manual Order',
  add_items = 'Add Items',
  remove_from_cart = 'Remove From Cart',
  search = 'Search',
  scroll_list = 'Scroll List',
  click_link = 'Click Link',
  toggle_on_or_off = 'Toggle on or off',
  view_order = 'View Order',
  notification_subscribed = 'Notifications Subscribed',
  notification_unsubscribed = 'Notifications not Subscribed',
  notification_came = 'Order notification came',
  notification_sound = 'Notiication Sound',
  invoke_enable_sound_alert = 'Invoke Enable Sound Alert',
}
@Injectable({
  providedIn: 'root',
})
export class MixpanelService implements OnDestroy {
  merchantSubscription$: any = null;
  merchant_details: any = {
    merchant_chain_id: null,
    merchant_outlet_id: null,
  };
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private dataService: DataService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      mixpanel.init(environment.MIXPANEL_ID, {
        debug: false,
        // loaded: function (mx) {
        // growth_book.setAttributes({
        //   ...growth_book.getAttributes(),
        // id: mx.get_distinct_id(),
        // });
        // }
      });
      this.initialise();
    }
  }

  ngOnDestroy() {
    if (this.merchantSubscription$) {
      this.merchantSubscription$.unsubscribe();
    }
  }

  initialise() {
    this.merchantSubscription$ = this.dataService.refresh_variable.subscribe(
      (merchant_login: any) => {
        if (merchant_login) {
          let merchant = this.dataService.getDataFromStore('merchant_info');
          this.merchant_details.merchant_chain_id = merchant.chain_id
            ? merchant.chain_id
            : null;
          this.merchant_details.merchant_outlet_id = merchant.id;
          // mixpanel.people.append('merchant_id', merchant.id);
          this.setSuperProperties(this.merchant_details);
        }
      }
    );
  }
  getDistinctId() {
    return mixpanel.get_distinct_id();
  }

  setSuperProperties(super_prop_obj: any) {
    mixpanel.register(super_prop_obj);
  }

  identifyUser(merchant_id: string) {
    mixpanel.identify(merchant_id);
  }

  trackEvents(name: string, event_obj?: any) {
    event_obj = event_obj ? event_obj : {};
    Object.assign(event_obj, this.merchant_details);
    mixpanel.track(MixpanelEvents[name], event_obj);
  }

  trackLinks(id: string, source: string) {
    mixpanel.track_links(id, MixpanelEvents.click_link, { source: source });
  }
}
