import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Event as NavigationEvent } from '@angular/router';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { DataService } from '../../utility/services/data-sharing.service';
import { ApiService } from '../../utility/services/api.service';
import { WindowRef } from '../../utility/services/window.service';
import { ModalService } from '../modal';
import { environment } from '../../../environments/environment';
import { UntypedFormControl } from '@angular/forms';
import { MixpanelService } from '../../utility/services/mixpanel.service';
@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
    base_url: string = environment.RAILS_API;
    menu_type: string = 'menu';
    merchant_info: any;
    show_notification: boolean = false;
    sidebar_list: any = [
        {
            name: 'Active orders',
            link: '/active-orders',
        },
        {
            name: 'Recent orders',
            link: '/recent-orders',
        },
        {
            name: 'Inventory',
            link: '/inventory',
        },
        {
            name: 'Settings',
            link: '/settings',
        },
    ];
    active_link: string;
    current_url: any;
    new_orders: any = [];
    is_pre_orders: boolean = false;
    pre_orders: any = [];
    new_orders_subscription$: any;
    audio: any;
    audio_err: boolean = false;
    // play_back_started:boolean=false;
    info: any = {};
    main_modal_styles: any = { width: '40vw' };
    is_new_notification: boolean = false;
    new_notification: any;
    searchOption = new UntypedFormControl();
    showSelectList: boolean = false;
    selected_outlet: any;
    outlet_list: any;
    showBanner = true;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private modalService: ModalService,
        private winRef: WindowRef,
        private apiService: ApiService,
        private dataService: DataService,
        private http: HttpClient,
        private mixpanelService: MixpanelService
    ) {
        router.events.subscribe((val: NavigationEvent) => {
            if (val instanceof NavigationEnd) {
                this.active_link = val.url;
                if (this.active_link === '/') {
                    this.active_link = '/active-orders';
                }
            }
        });
    }
    ngOnInit(): void {
        this.initialise(() => {
            this.merchant_info = this.dataService.getDataFromStore('merchant_info');
            this.outlet_list = this.dataService.getDataFromStore('outlet_list');
            this.merchant_info['name_location'] = this.merchant_info.name + ' - ' + this.merchant_info.location;
            this.selected_outlet = this.merchant_info;

            this.audio = new Audio();
            this.audio.src = '../../../assets/audio/382236__sforsman__1alarm.mp3';
            this.audio.load();

            this.winRef.nativeWindow.OneSignal = this.winRef.nativeWindow.OneSignal || [];
            this.winRef.nativeWindow.OneSignal.push(() => {
                this.winRef.nativeWindow.OneSignal.init({
                    appId: environment.ONE_SIGNAL_APP_ID,
                });
            });
            this.winRef.nativeWindow.OneSignal.push(() => {
                this.winRef.nativeWindow.OneSignal.setExternalUserId(
                    String(this.merchant_info.id)
                );
                this.winRef.nativeWindow.OneSignal.on('notificationDisplay', (notification: any) => {
                    this.new_notification = notification;
                    // this.play_back_started=false;
                    this.mixpanelService.trackEvents('notification_came', {
                        notification_came_at: new Date(),
                        notification_id: notification.id,
                        notification_content: notification.content,
                    });
                    this.playAudio();
                    this.modalService.open('newOrderAlert');
                    if (this.router.url.includes('/active-orders')) {
                        this.dataService.setData('list_of_orders', true);
                    }
                });
            });

            //check and prompt the user every 24 hours to subscribe browser notifications if not subscribed
            const notificationSubscriptionInterval = setInterval(() => {
                this.winRef.nativeWindow.OneSignal.getNotificationPermission(
                    (permission) => {
                        if (permission == 'granted') {
                            // The user has already subscribed to notifications
                            clearInterval(notificationSubscriptionInterval);
                            this.mixpanelService.trackEvents('notification_subscribed', {
                                permission_status: permission,
                            });
                        } else {
                            this.mixpanelService.trackEvents('notification_unsubscribed', {
                                permission_status: permission,
                            });
                            // The user has previously denied notifications
                            // or The user has not yet been prompted to allow notifications
                            // You can trigger a prompt asking them to subscribe
                            this.winRef.nativeWindow.OneSignal.registerForPushNotifications();
                        }
                    }
                );
            }, 24 * 60 * 60 * 1000);
        });
    }

    closeBanner() {
        this.apiService.postData('/order_dashboard/api/v1/merchants/update_show_banner', { show_banner: false }).subscribe(() => {
            this.getMerchantInfo(() => { });
            this.showBanner = false;
        });
    }

    //initialise
    initialise(cb) {
        this.getMerchantInfo(() => {
            cb();
            //check for pre-orders
            if (
                this.merchant_info &&
                (this.merchant_info.pre_order_delivery ||
                    this.merchant_info.pre_order_take_away)
            ) {
                this.sidebar_list.splice(1, 0, {
                    name: 'Future orders',
                    link: '/future-orders',
                });
                this.is_pre_orders = true;
            }
        });
    }

    //get merchant info
    getMerchantInfo(cb) {
        this.apiService
            .getData('/order_dashboard/api/v1/merchants/info', null)
            .subscribe((merchant_config: any) => {
                let order_config = {
                    statuses: merchant_config.statuses,
                    tabs: merchant_config.tabs,
                };
                this.dataService.setDataInStore('order_config', order_config);
                this.dataService.setDataInStore(
                    'merchant_info',
                    merchant_config.merchant
                );
                this.dataService.setDataInStore('order_type', merchant_config.tabs);
                this.dataService.setDataInStore(
                    'item_statuses',
                    merchant_config.statuses
                );
                this.dataService.setData('localStorageUpdated', true);
                cb();
            });
    }

    //close new alert modal
    closeAlertModal(event: any, id: string) {
        if (this.audio) {
            this.audio.pause();
            this.audio.currentTime = 0;
        }
        this.modalService.close(id);
    }

    enableSound(event: any) {
        this.mixpanelService.trackEvents('invoke_enable_sound_alert');
        this.modalService.close('enableSoundAlert');
        if (this.audio_err) {
            this.playAudio();
        }
    }

    //notification sound
    playAudio() {
        if (this.audio.paused) {
            let promise = this.audio.play();
            if (promise !== undefined) {
                promise
                    .then((success: any) => {
                        // Autoplay started!
                        this.mixpanelService.trackEvents('notification_sound', {
                            play_status: true,
                        });
                        this.audio_err = false;
                    })
                    .catch((error: any) => {
                        error = typeof error === 'object' ? JSON.stringify(error) : error;
                        let data = { message: 'sound error is ' + error };
                        this.mixpanelService.trackEvents('notification_sound', {
                            play_status: false,
                            error_reason: data.message,
                        });
                        this.audio_err = true;
                        this.modalService.open('enableSoundAlert');
                        // Autoplay was prevented.
                        // Show a "Play" button so that user can start playback.
                    });
            }
        }
    }

    openSidebar(type: string) {
        this.menu_type = type === 'menu' ? 'cancel' : 'menu';
    }

    toggleNotification(element) {
        this.show_notification =
            element.id === 'notification_button' ? !this.show_notification : false;
    }

    closeSidebar() {
        this.menu_type = 'menu';
    }

    //function to navigate
    navigateTo(url: string) {
        this.router.navigate([url]);
        this.active_link = url;
        this.closeSidebar();
        this.mixpanelService.trackEvents('click_link', {
            cta: 'Home',
            url: this.active_link,
            source: 'Sidebar',
        });
    }

    logout() {
        this.mixpanelService.trackEvents('logout', {
            merchant_id: this.merchant_info.id,
        });
        this.dataService.clearDataInStore();
        this.dataService.setData('loggedInStatus', false);
        this.dataService.setData('localStorageUpdated', false);
        this.router.navigate(['login']);
    }

    // custome serch and select drop down
    toggleSelectList(area: string) {
        this.showSelectList = area === 'inside' ? !this.showSelectList : false;
    }
    // select custom search and select drop down value
    selectOption(option: any) {
        this.searchOption.reset();
        this.showSelectList = false;
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: option.authentication_token,
        });
        this.http
            .post(
                this.base_url + '/contactless_dining/api/v1/merchants/login',
                null,
                { headers: headers }
            )
            .subscribe(
                (login_data: any) => {
                    this.merchant_info = login_data.merchant;
                    this.merchant_info['name_location'] =
                        this.merchant_info.name + ' - ' + this.merchant_info.location;
                    this.selected_outlet = this.merchant_info;
                    this.dataService.setDataInStore(
                        'staff_auth_token',
                        login_data.merchant.authentication_token
                    );
                    let order_config = {
                        statuses: login_data.statuses,
                        tabs: login_data.tabs,
                    };
                    this.dataService.setDataInStore('order_config', order_config);
                    this.dataService.setDataInStore('merchant_info', login_data.merchant);
                    this.dataService.setDataInStore('order_type', login_data.tabs);
                    this.dataService.setDataInStore('item_statuses', login_data.statuses);
                    this.dataService.setData('localStorageUpdated', true);
                    if (this.router.url === '/active-orders') {
                        window.location.reload();
                    } else {
                        this.router.navigateByUrl('/active-orders');
                    }
                },
                (err) => {
                    this.dataService.setData('loggedInStatus', false);
                }
            );
    }

    openPopupForDownloadApp() {
        this.modalService.open('orderAppPopup');
    }
}
