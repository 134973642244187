import {
    Component,
    Input,
    OnChanges,
    ViewChild,
    ElementRef,
    EventEmitter,
    Output,
} from '@angular/core';
import { ApiService } from '../../utility/services/api.service';
import {
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators,
} from '@angular/forms';
import { status_list } from '../../features/active-orders/order.config';
import { DataService } from '../../utility/services/data-sharing.service';
import { ModalService } from './../modal';
import * as _ from 'lodash';
import { MixpanelService } from '../../utility/services/mixpanel.service';
@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
})
export class OrderComponent implements OnChanges {
    editDateTimeForm: UntypedFormGroup;
    @Input() order_opened: any;
    @Input() show_status: boolean;
    @Input() card_lane: string;
    @Input() is_open: boolean = false;
    @Output() onAction = new EventEmitter<string>();
    @Output() statusHandling = new EventEmitter<Object>();
    is_customize: boolean = false;
    show_edit_icon: any = ['in kitchen', 'accepted', 'food_ready', 'served'];
    order_info: any = null;
    merchant_info: any;
    modal_styles: any = { add_items: { width: '60%', height: '60%' } };
    is_add_items_open: boolean = false;
    edit_delivery_type: boolean = false;
    delivery_date_time = { date: [], time: [], clicked: false };
    customer_info_keys: string[];
    order_sum_keys: string[];
    submitted: boolean = false;
    change_time_styles: any = { width: '47vw' };
    status_list: any = status_list;
    ordering_mode: string;
    show_separate_kot: Array<string> = [
        'pending',
        'accepted',
        'in kitchen',
        'food_ready',
    ];
    order_types_name: any = {
        take_away: 'pickup',
        delivery: 'delivery',
        dine_in: 'dine in',
    };
    edit_mode_on: boolean = false;
    is_order_edit: boolean = false;
    order_groups: Array<string> = [];
    @ViewChild('orderDetails') orderDetails: ElementRef;
    constructor(
        private apiService: ApiService,
        private modalService: ModalService,
        private formBuilder: UntypedFormBuilder,
        private dataService: DataService,
        private mixpanelService: MixpanelService
    ) { }

    ngOnInit(): void { }

    ngOnChanges() {
        if (this.is_open) {
            this.merchant_info = this.dataService.getDataFromStore('merchant_info');
            if (this.card_lane === 'served') {
                this.getOrderGroupinfo();
            } else {
                this.initialise();
            }
        }
    }

    initialiseForPrinting() {
        this.merchant_info = this.dataService.getDataFromStore('merchant_info');
        this.initialise('print');
    }

    initialise(...args: any) {
        this.customer_info_keys = [];
        this.order_sum_keys = [];
        this.order_info = null;
        this.edit_mode_on = false;
        this.is_order_edit = false;
        if (args && args[0]) {
            this.getOrderinfo(args[0]);
        } else {
            this.getOrderinfo();
        }
    }

    getOrderinfo(...args: any) {
        let params: any = this.order_opened.is_table_ordering
            ? { is_table_ordering: true }
            : null;
        this.apiService
            .getData(
                '/order_dashboard/api/v1/orders/' + this.order_opened.order_id,
                params
            )
            .subscribe((result: any) => {
                this.order_info = result;
                // console.log(result);
                if (result?.delivery_types?.length > 1) {
                    if(this.order_info.order_source === 'ONDC'){
                        this.edit_delivery_type = false;  
                    } else if(this.order_info.order_source === 'Consumer'){
                        if(!this.merchant_info.self_delivery_enabled_on_consumer_app){
                            this.edit_delivery_type = false;
                        } else {
                            this.edit_delivery_type = true;
                        }
                    } else{
                        this.edit_delivery_type = true;  
                    }
                }else{
                    this.edit_delivery_type = false;
                }
                this.ordering_mode = this.order_opened.is_table_ordering
                    ? 'table_ordering'
                    : result.order_summary.type;
                this.customer_info_keys = Object.keys(result.customer_details);
                this.order_sum_keys = Object.keys(result.order_summary);
                if (args && args[0] === 'print') {
                    this.print();
                }
                this.order_opened.delivery_type = result.order_summary.delivery_type;
            });
    }

    getOrderGroupinfo() {
        let params: any = { visit_id: this.order_opened.visit_id };
        this.apiService
            .getData('/order_dashboard/api/v1/orders/order_groups', params)
            .subscribe((result: any) => {
                this.order_groups = result;
                this.order_opened['order_id'] = result[0];
                this.getOrderinfo();
            });
    }

    changeOrderId(id: string) {
        this.order_opened['order_id'] = id;
        this.getOrderinfo();
    }

    editOrderType(operation: any) {
        this.onAction.next(operation);
    }

    //update order status
    updateOrderStatus(status: string) {
        switch (status) {
            case 'cancelled':
                this.onAction.next(status);
                break;
            case 'accepted':
                this.onAction.next(status);
                break;
            default:
                let data = { status: status };
                if (this.order_opened.is_table_ordering) {
                    data['is_table_ordering'] = true;
                }
                this.apiService
                    .postData(
                        '/order_dashboard/api/v1/orders/' + this.order_info.id + '/status',
                        data
                    )
                    .subscribe((result: any) => {
                        this.getOrderinfo();
                        this.onAction.next(status);
                    });
        }
    }

    editDeliveryTime() {
        this.editDateTimeForm = this.formBuilder.group({
            pre_order_date: ['', Validators.required],
            pre_order_time: ['', Validators.required],
        });
        this.apiService
            .getData('/order_dashboard/api/v1/merchants/pre_order_date', null)
            .subscribe((data: any) => {
                this.delivery_date_time.date = data;
            });
        this.modalService.open('deliveryDateTime');
    }
    get f() {
        return this.editDateTimeForm.controls;
    }

    onChangeDate(date: any) {
        let params = {
            date: date,
            order_type: this.order_info.order_summary.type,
        };
        this.apiService
            .getData('/order_dashboard/api/v1/merchants/pre_order_time', params)
            .subscribe((data: any) => {
                this.delivery_date_time.time = data;
            });
    }

    updateDeliveryDateAndTime() {
        this.submitted = true;
        if (this.editDateTimeForm.valid) {
            this.delivery_date_time.clicked = true;
            this.apiService
                .putData(
                    '/order_dashboard/api/v1/orders/' + this.order_opened.order_id,
                    this.editDateTimeForm.value
                )
                .subscribe(
                    (data: any) => {
                        this.order_info.order_time = data.schedule_time;
                        this.closeModal('deliveryDateTime');
                        this.delivery_date_time.clicked = false;
                        this.submitted = false;
                    },
                    (err: any) => {
                        this.delivery_date_time.clicked = false;
                        this.submitted = false;
                    }
                );
        }
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }
    // enable edit order
    editOrderEnable() {
        if (this.order_info.can_edit_order) {
            this.edit_mode_on = true;
        } else {
            this.order_info['not_editable'] =
                'Sorry, you cannot edit this order as their is a discount applied on it.';
        }
    }
    //edit order
    edit(action: number) {
        if (action) {
            this.apiService
                .postData(
                    '/order_dashboard/api/v1/orders/' +
                    this.order_info.id +
                    '/update_order',
                    this.order_info.order_details
                )
                .subscribe(
                    (data: any) => {
                        this.edit_mode_on = false;
                        this.is_order_edit = false;
                        let notification = {
                            message: 'Order successfuly updated.',
                            headline: 'Success',
                            icon: 'success',
                        };
                        this.statusHandling.next(notification);
                    },
                    (error) => {
                        let notification = {
                            message: 'Something went wrong.Try again later.',
                            headline: 'Failure',
                            icon: 'failure',
                        };
                        this.statusHandling.next(notification);
                    }
                );
        } else {
            this.is_order_edit = false;
            this.edit_mode_on = false;
            this.getOrderinfo();
        }
    }

    //increase quantity
    increaseQty(item: any) {
        //mix panel
        let mixpanel_item_obj: any = {
            order_id: this.order_info.id,
            item_id: item.pos_item_id,
            item_name: item.name,
            item_price: item.item_price,
            item_category: item.pos_category_name,
            item_quantity: 1,
            source: 'Active orders page',
        };
        this.mixpanelService.trackEvents('add_items', mixpanel_item_obj);
        item.quantity =
            item.quantity > 0 ? Number(item.quantity) + 1 : item.quantity;
        this.updateOrderApi('increment');
    }

    //increase quantity
    decreaseQty(item: any) {
        item.quantity =
            item.quantity > 0 ? Number(item.quantity) - 1 : item.quantity;
        this.updateOrderApi('decrement');
        //mix panel
        let mixpanel_item_obj: any = {
            order_id: this.order_info.id,
            item_id: item.pos_item_id,
            item_name: item.name,
            item_price: item.item_price,
            item_category: item.pos_category_name,
            item_quantity: 1,
            source: 'Active orders page',
        };
        this.mixpanelService.trackEvents('remove_from_cart', mixpanel_item_obj);
    }
    // delete item form cart
    deleteItem(item: any) {
        item.quantity = 0;
        //mix panel
        let mixpanel_item_obj: any = {
            order_id: this.order_info.id,
            item_id: item.pos_item_id,
            item_name: item.name,
            item_price: item.item_price,
            item_category: item.pos_category_name,
            item_quantity: 1,
            source: 'Active orders page',
        };
        this.mixpanelService.trackEvents('remove_from_cart', mixpanel_item_obj);
        this.updateOrderApi('decrement');
    }
    //add item
    addItem() {
        this.is_customize = false;
        this.is_add_items_open = true;
        this.modalService.open('addItems');
    }

    updateOrderApi(obj: any) {
        this.is_order_edit = true;
        let item_array = [];
        let items = this.order_info.order_details.items;
        if (typeof obj === 'object') {
            let idx = items.findIndex((value) => {
                return value.id === obj.id;
            });
            if (idx > -1) {
                if (
                    (obj.variations && obj.variations.length) ||
                    (obj.addons && obj.addons.length) ||
                    (obj.combos && obj.combos.length)
                ) {
                    // same item which have variation only
                    if (obj.variations && obj.variations.length && !obj.addons) {
                        let isSameVariation = false;
                        items[idx].custom.forEach((custom) => {
                            if (
                                custom.type === 'variation' &&
                                custom.id === obj.variations[0].selected_variation
                            ) {
                                isSameVariation = true;
                            }
                        });
                        // if item have same variation like which same item contain
                        if (isSameVariation) {
                            items[idx].quantity = Number(items[idx].quantity) + 1;
                        } else {
                            // same item has diff variations
                            let variation_obj: any;
                            obj.variations[0].items.forEach((e) => {
                                if (e.variation_id === obj.variations[0].selected_variation) {
                                    variation_obj = e;
                                }
                            });
                            item_array.push({
                                id: obj.id,
                                qty: 1,
                                addons: [],
                                variations: [
                                    {
                                        id: variation_obj.id,
                                        name: variation_obj.name,
                                        type: 'variation',
                                    },
                                ],
                            });
                        }
                    }
                    // same item has addon only
                    if (obj.addons && obj.addons.length && !obj.variations) {
                        let addonitem = obj.addons;
                        let oldaddon = [];
                        let newaddon = [];
                        for (let i = 0; i < addonitem.length; i++) {
                            for (let j = 0; j < addonitem[i].items.length; j++) {
                                if (
                                    items[idx].custom[j] &&
                                    items[idx].custom[j].type === 'addon'
                                ) {
                                    let aid = oldaddon.findIndex((value) => {
                                        return value.id === items[idx].custom[j].id;
                                    });
                                    if (aid === -1) {
                                        oldaddon.push({
                                            id: items[idx].custom[j].id,
                                            name: items[idx].custom[j].name,
                                            type: 'addon',
                                        });
                                    }
                                }
                                if (addonitem[i].items[j].checked) {
                                    newaddon.push({
                                        id: addonitem[i].items[j].addon_id,
                                        name: addonitem[i].items[j].name,
                                        type: 'addon',
                                    });
                                }
                            }
                        }
                        // check old item has same addon or not
                        this.editOrderComparison(
                            'addons',
                            item_array,
                            items,
                            idx,
                            obj,
                            oldaddon,
                            newaddon
                        );
                    }
                    // check existing item has combos
                    if (
                        obj.combos &&
                        obj.combos.length &&
                        !obj.addons.length &&
                        !obj.variations.length
                    ) {
                        let combos = obj.combos;
                        let oldcombos = [];
                        let newcombos = [];
                        for (let i = 0; i < combos.length; i++) {
                            for (let j = 0; j < combos[i].items.length; j++) {
                                if (
                                    items[idx].custom[j] &&
                                    items[idx].custom[j].type === 'combo'
                                ) {
                                    let cid = oldcombos.findIndex((value) => {
                                        return value.id === items[idx].custom[j].id;
                                    });
                                    if (cid === -1) {
                                        oldcombos.push({
                                            id: items[idx].custom[j].id,
                                            name: items[idx].custom[j].name,
                                            type: 'combo',
                                        });
                                    }
                                }
                                if (combos[i].items[j].checked) {
                                    newcombos.push({
                                        id: combos[i].items[j].combo_id,
                                        name: combos[i].items[j].name,
                                        type: 'combo',
                                    });
                                }
                            }
                        }
                        // check existing item has same combo or not
                        this.editOrderComparison(
                            'combos',
                            item_array,
                            items,
                            idx,
                            obj,
                            oldcombos,
                            newcombos
                        );
                    }
                } else {
                    // add quantity in existing item
                    items[idx].quantity = Number(items[idx].quantity) + 1;
                }
                // check old item has variation and addons
                if (
                    obj.variations &&
                    obj.variations.length &&
                    obj.addons &&
                    obj.addons.length
                ) {
                    let addonitem = obj.addons;
                    let oldaddvar = [];
                    let newAddon = [];
                    let variation_obj: any;
                    obj.variations[0].items.forEach((e: any) => {
                        if (e.variation_id === obj.variations[0].selected_variation) {
                            variation_obj = e;
                        }
                    });
                    let newaddvar = [
                        { id: variation_obj.variation_id, name: variation_obj.name },
                    ];
                    for (let i = 0; i < addonitem.length; i++) {
                        for (let j = 0; j < addonitem[i].items.length; j++) {
                            if (addonitem[i].items[j].checked) {
                                newaddvar.push({
                                    id: addonitem[i].items[j].addon_id,
                                    name: addonitem[i].items[j].name,
                                });
                                newAddon.push({
                                    id: addonitem[i].items[j].addon_id,
                                    name: addonitem[i].items[j].name,
                                    type: 'addon',
                                });
                            }
                        }
                    }
                    let custom = items[idx].custom;
                    for (let i = 0; i < custom.length; i++) {
                        oldaddvar.push({ id: custom[i].id, name: custom[i].name });
                    }
                    // check old item has same variation and addon
                    if (_.isEqual(_.sortBy(oldaddvar, 'id'), _.sortBy(newaddvar, 'id'))) {
                        items[idx].quantity = Number(items[idx].quantity) + 1;
                    } else {
                        item_array.push({
                            id: obj.id,
                            qty: 1,
                            addons: newAddon,
                            variations: [
                                {
                                    id: variation_obj.variation_id,
                                    name: variation_obj.name,
                                    type: 'variation',
                                },
                            ],
                            combos: [],
                        });
                    }
                }
            } else {
                // add new item in cart who have no variations,addons
                if (!obj.variations && !obj.addons) {
                    item_array.push({
                        id: obj.id,
                        qty: 1,
                        addons: [],
                        variations: [],
                        combos: [],
                    });
                } else {
                    // add new item in cart who have variation
                    let variation = [];
                    let addon = [];
                    let combo = [];
                    if (obj.variations && obj.variations.length) {
                        let variation_obj;
                        obj.variations[0].items.forEach((e) => {
                            if (e.variation_id === obj.variations[0].selected_variation) {
                                variation_obj = e;
                            }
                        });
                        variation.push({
                            id: variation_obj.variation_id,
                            name: variation_obj.name,
                            type: 'variation',
                        });
                    }
                    // add new item who have addon
                    if (obj.addons && obj.addons.length) {
                        this.addNewAddonAndCombos(obj, 'addons', addon, 'addon');
                    }
                    // Add new item who have combo options

                    if (obj.combos && obj.combos.length) {
                        this.addNewAddonAndCombos(obj, 'combos', combo, 'combo');
                    }
                    // push new item in item array variable
                    item_array.push({
                        id: obj.id,
                        qty: 1,
                        addons: addon,
                        variations: variation,
                        combos: combo,
                    });
                }
            }
        }

        // add old item in item array
        for (let i = 0; i < items.length; i++) {
            let variation = [];
            let addon = [];
            let combo = [];
            if (items[i].custom && items[i].custom.length) {
                for (let j = 0; j < items[i].custom.length; j++) {
                    if (items[i].custom[j].type === 'variation') {
                        variation.push({
                            id: items[i].custom[j].id,
                            name: items[i].custom[j].name,
                            type: 'variation',
                        });
                    } else if (items[i].custom[j].type === 'addon') {
                        addon.push({
                            id: items[i].custom[j].id,
                            name: items[i].custom[j].name,
                            type: 'addon',
                        });
                    } else {
                        combo.push({
                            id: items[i].custom[j].id,
                            name: items[i].custom[j].name,
                            type: 'combo',
                        });
                    }
                }
            }
            // if item qty 0 then leave it
            if (Number(items[i].quantity) > 0) {
                item_array.push({
                    id: items[i].id,
                    qty: Number(items[i].quantity),
                    addons: addon,
                    variations: variation,
                    combos: combo,
                });
            }
        }
        let newOrder = { items_data: item_array, order_id: this.order_info.id };
        this.apiService
            .postData(
                '/order_dashboard/api/v1/orders/' +
                this.order_info.id +
                '/update_cart_items',
                newOrder
            )
            .subscribe((data: any) => {
                this.order_info['order_details'] = data;
            });
    }

    // add new addon and combos
    addNewAddonAndCombos(obj: any, types: any, array: any, itemtype: any) {
        let list = obj[types];
        for (let i = 0; i < list.length; i++) {
            for (let j = 0; j < list[i].items.length; j++) {
                if (list[i].items[j].checked) {
                    array.push({
                        id: list[i].items[j][itemtype + '_id'],
                        name: list[i].items[j].name,
                        type: itemtype,
                    });
                }
            }
        }
    }

    // compair values and add to in object
    editOrderComparison(
        type: any,
        item_array: any,
        items: any,
        idx: any,
        obj: any,
        olditem: any,
        newitem: any
    ) {
        if (_.isEqual(_.sortBy(olditem, 'id'), _.sortBy(newitem, 'id'))) {
            items[idx].quantity = Number(items[idx].quantity) + 1;
        } else {
            let object = {
                id: obj.id,
                qty: 1,
                addons: [],
                variations: [],
                combos: [],
            };
            switch (type) {
                case 'addons':
                    object.addons = newitem;
                    break;
                case 'combos':
                    object.combos = newitem;
                    break;
            }
            item_array.push(object);
        }
    }

    customizeEvent(value: any) {
        this.is_customize = value;
    }
    //edit table
    editTableNumber() {
        this.onAction.next('edit_table');
    }
    // tax breakdown
    taxBreakdown() {
        this.modalService.open('taxBreakdown');
    }
    //print order items
    print() {
        let items: Array<any> = this.order_info.order_details.items;
        let food_items: Array<any> = [];
        let liquor_items: Array<any> = [];
        let food_sub_total: number = 0;
        let liquor_sub_total: number = 0;
        if (
            this.order_info.table_id &&
            this.show_separate_kot.indexOf(this.order_info.order_status) > -1
        ) {
            for (let j: number = 0; j < items.length; j++) {
                if (items[j].is_liquor) {
                    liquor_items.push(items[j]);
                    liquor_sub_total += items[j].quantity * items[j].total;
                } else {
                    food_items.push(items[j]);
                    food_sub_total += items[j].quantity * items[j].total;
                }
            }
        } else {
            food_items = items;
        }

        const WindowPrt = window.open(
            '',
            '',
            'left=0,top=0,width=600,height=auto,toolbar=0,scrollbars=0,status=0'
        );
        WindowPrt.document.write(
            `<html>
        <head>
          <style>
          h4{
            letter-spacing: 0.09px;
            text-align: center;
            margin:10px 0px;
            font-size: 17px;
          }
          .distance_frm_outlet{
            display:none;
          }
          tr td,.kot_info{
            font-size:14px;
            padding:6px 0px;
          }
          .brand_name{
            font-size:11px;
          }
          .w_50{
            width:50%;
          }
          .w_25{
            width:25%;
          }
          .w_100{
            width:100%;
          }
          .main_total{
            font-size:17px;
            font-weight:bold;
          }
          .addon{
            padding:6px 0px 0px 10px;
            font-size:10px;
          }
          #kot_bill{
            padding:2mm;
            height:auto;
          }
          .space_top{
            margin-top:10px;
          }
          .dashed_border{
            border-top: 1px dashed #000000;
            border-bottom: 1px dashed #000000;
          }

          tr.border_top td{
            border-top: 1px dashed #000000 !important;
          }
          </style>
    `
        );
        WindowPrt.document.write(
            `</head>
      <body id="kot_bill">
      <h4>${this.merchant_info.name + ', ' + this.merchant_info.location}</h4>`
        );
        if (
            this.order_info.table_id &&
            this.show_separate_kot.indexOf(this.order_info.order_status) > -1
        ) {
            WindowPrt.document.write('<h4>Kitchen</h4>');
        }
        WindowPrt.document.write(`<div class="kot_info">Order No: ${this.order_info.id
            }</div>
      <div class="kot_info">Order Type: ${(this.order_types_name[
                this.order_info.order_summary.type
            ] =
                this.order_types_name[this.order_info.order_summary.type]
                    .charAt(0)
                    .toUpperCase() +
                this.order_types_name[this.order_info.order_summary.type]
                    .substring(1)
                    .toLowerCase())}</div>
      <div class="kot_info">Placed at: ${this.order_info.placed_at}</div>
      ${this.order_info.brand
                ? '<div class="kot_info">Brand:' + this.order_info.brand + '</div>'
                : ''
            }
      <div class="kot_info ${this.order_info.order_summary.type !== 'dine_in'
                ? ''
                : 'distance_frm_outlet'
            }">${this.order_info.order_summary.type === 'take_away'
                ? 'Pickup time'
                : 'Delivery time'
            }: ${this.order_info.order_time}</div>
      <div  class="${this.order_info.table_number ? '' : 'distance_frm_outlet'
            } kot_info">Table number: ${this.order_info.table_number}</div>
      <div class="kot_info">Name: ${this.order_info.customer_details.name}</div>
      <div class="kot_info">Mobile: ${this.order_info.customer_details.contact
            }</div>
      <div  class="${this.order_info.customer_details.address ? '' : 'distance_frm_outlet'
            } kot_info">Address: ${this.order_info.customer_details.address}</div>
      ${this.order_info.order_details.special_intructions
                ? '<div class="kot_info">Special instructions: ' +
                this.order_info.order_details.special_intructions +
                '</div>'
                : ''
            }
      <table class="w_100 space_top">
        <tr>
          <td align="left" class="w_50 dashed_border"><b>Item</b></td>
          <td align="center" class="w_25 dashed_border"><b>Qty</b></td>
          <td align="center" class="w_25 dashed_border"><b>Total</b></td>
        </tr>
    `);
        for (let i: number = 0; i < food_items.length; i++) {
            WindowPrt.document.write(`<tr>
                                  <td align="left">${(food_items[i].name =
                    food_items[i].name.charAt(0).toUpperCase() +
                    food_items[i].name
                        .substring(1)
                        .toLowerCase())}
                                `);
            if (food_items[i].custom && food_items[i].custom.length) {
                for (let c: number = 0; c < food_items[i].custom.length; c++) {
                    WindowPrt.document.write(
                        `<div class="addon">${(food_items[i].custom[c].name =
                            food_items[i].custom[c].name.charAt(0).toUpperCase() +
                            food_items[i].custom[c].name.substring(1).toLowerCase())}</div>`
                    );
                }
            }
            if (this.order_info.brand && this.order_info.brand === 'Multiple') {
                WindowPrt.document.write(
                    `<div class="brand_name">( ${food_items[i].brand_name} )</div>`
                );
            }
            WindowPrt.document.write(`</td>`);
            WindowPrt.document
                .write(`<td valign="top" align="center" class="w_25">${food_items[i].quantity}</td>
                                  <td valign="top" align="center" class="w_25">₹${food_items[i].total}</td>
                                </tr>`);
        }
        if (
            this.order_info.order_details &&
            (this.order_info.order_details.discount > 0 ||
                this.order_info.order_details.extra_charges > 0)
        ) {
            WindowPrt.document.write(`<tr class="border_top">
                                  <td align="left" class="w_50">Item Total</td>
                                  <td valign="top" align="center" class="w_25"></td>
                                  <td valign="top" align="center" class="w_25">₹${this.order_info.order_details.item_total}</td>
                                </tr>`);
        }
        if (
            this.order_info.order_details &&
            this.order_info.order_details.discount > 0
        ) {
            WindowPrt.document.write(`</tr>
                                  <td align="left" class="w_50">Total discount</td>
                                  <td valign="top" align="center class="w_25"></td>
                                  <td valign="top" align="center" class="w_25">₹${this.order_info.order_details.discount}</td>
                                </tr>`);
        }
        if (
            this.order_info.order_details &&
            this.order_info.order_details.extra_charges > 0
        ) {
            WindowPrt.document.write(`</tr>
                                  <td align="left" class="w_50">Taxes & Charges</td>
                                  <td valign="top" align="center class="w_25"></td>
                                  <td valign="top" align="center" class="w_25">₹${this.order_info.order_details.extra_charges}</td>
                                </tr>`);
        }
        if (this.order_info.order_details && this.order_info.order_details.tip) {
            WindowPrt.document.write(`</tr>
                                  <td align="left" class="w_50">Tip for your server</td>
                                  <td valign="top" align="center class="w_25"></td>
                                  <td valign="top" align="center" class="w_25">₹${this.order_info.order_details.tip}</td>
                                </tr>`);
        }

        WindowPrt.document.write(`<tr class="${this.order_info.order_details &&
            (this.order_info.order_details.discount > 0 ||
                this.order_info.order_details.extra_charges > 0)
            ? ''
            : 'border_top'
            }">
                                  <td align="left" class="w_50 main_total">Grand Total</td>
                                  <td valign="top" align="center class="w_25"></td>
                                  <td valign="top" align="center" class="w_25 main_total">₹${this.order_info.order_details.grand_total
            }</td>
                              </tr>`);

        if (this.order_info.order_summary.payment_mode) {
            WindowPrt.document.write(`</tr>
                                    <td align="left" class="w_50">Payment Mode:</td>
                                    <td valign="top" align="center class="w_25 "></td>
                                    <td valign="top" align="center" class="w_25">${(this.order_info.order_summary.payment_mode =
                    this.order_info.order_summary.payment_mode
                        .charAt(0)
                        .toUpperCase() +
                    this.order_info.order_summary.payment_mode
                        .substring(1)
                        .toLowerCase())}</td>
                                  </tr>`);
        }
        WindowPrt.document.write(`</table>`);
        // new code testing **************************
        if (
            this.order_info.table_id &&
            this.show_separate_kot.indexOf(this.order_info.order_status) > -1
        ) {
            WindowPrt.document.write(`<div style="break-after:page;" ></div>
      <h4>${this.merchant_info.name + ', ' + this.merchant_info.location}</h4>
      <h4>Bar</h4>
      <div class="kot_info">Order No: ${this.order_info.id}</div>
        <div class="kot_info">Order Type: ${(this.order_types_name[
                    this.order_info.order_summary.type
                ] =
                    this.order_types_name[this.order_info.order_summary.type]
                        .charAt(0)
                        .toUpperCase() +
                    this.order_types_name[this.order_info.order_summary.type]
                        .substring(1)
                        .toLowerCase())}</div>
        <div class="kot_info">Placed at: ${this.order_info.placed_at}</div>
        ${this.order_info.brand
                    ? '<div class="kot_info">Brand:' + this.order_info.brand + '</div>'
                    : ''
                }
        <div  class="kot_info">Table number: ${this.order_info.table_number
                }</div>
        <div class="kot_info">Name: ${this.order_info.customer_details.name
                }</div>
        <div class="kot_info">Mobile: ${this.order_info.customer_details.contact
                }</div>
        ${this.order_info.order_details.special_intructions
                    ? '<div class="kot_info">Special instructions: ' +
                    this.order_info.order_details.special_intructions +
                    '</div>'
                    : ''
                }
      <table class="w_100 space_top">
      <tr>
        <td align="left" class="w_50 dashed_border"><b>Item</b></td>
        <td align="center" class="w_25 dashed_border"><b>Qty</b></td>
        <td align="center" class="w_25 dashed_border"><b>Total</b></td>
      </tr>`);
            for (let i: number = 0; i < liquor_items.length; i++) {
                WindowPrt.document.write(`<tr>
                                    <td align="left">${(liquor_items[i].name =
                        liquor_items[i].name
                            .charAt(0)
                            .toUpperCase() +
                        liquor_items[i].name
                            .substring(1)
                            .toLowerCase())}
                                  `);
                if (liquor_items[i].custom && liquor_items[i].custom.length) {
                    for (let c: number = 0; c < liquor_items[i].custom.length; c++) {
                        WindowPrt.document.write(
                            `<div class="addon">${(liquor_items[i].custom[c].name =
                                liquor_items[i].custom[c].name.charAt(0).toUpperCase() +
                                liquor_items[i].custom[c].name
                                    .substring(1)
                                    .toLowerCase())}</div>`
                        );
                    }
                }
                if (this.order_info.brand && this.order_info.brand === 'Multiple') {
                    WindowPrt.document.write(
                        `<div class="brand_name">( ${liquor_items[i].brand_name} )</div>`
                    );
                }
                WindowPrt.document.write(`</td>`);
                WindowPrt.document
                    .write(`<td valign="top" align="center" class="w_25">${liquor_items[i].quantity}</td>
                                    <td valign="top" align="center" class="w_25">₹${liquor_items[i].total}</td>
                                  </tr>`);
            }
            WindowPrt.document.write(`<tr class="border_top"}>
                                    <td align="left" class="w_50 main_total">Grand Total</td>
                                    <td valign="top" align="center class="w_25"></td>
                                    <td valign="top" align="center" class="w_25 main_total">₹${liquor_sub_total}</td>
                                </tr>`);
        }
        // new code testing end *******************
        WindowPrt.document.write('</body></html>');
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
    }
}
