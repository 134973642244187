import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-loader',
  templateUrl: './skeleton-loader.component.html',
  // styleUrls: ['./skeleton-loader.component.scss ']
})
export class SkeletonLoaderComponent implements OnInit {
  @Input() type: string;
  @Input() details: any;
  @Input() count: any;
  showSearch: boolean = false;
  constructor() {}

  ngOnInit(): void {
    switch (this.type) {
      case 'box':
        if (!this.details) {
          this.details.styles = {};
        }
        break;
    }
  }
}
