//modules
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
// import * as Sentry from "@sentry/angular";
//components
import { AppComponent } from './app.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { MainComponent } from './shared/main/main.component';
import { NotificationComponent } from './shared/notification/notification.component';
//services
import { DataService } from './utility/services/data-sharing.service';
import { WindowRef } from './utility/services/window.service';
import { ApiService } from './utility/services/api.service';
import { WebsocketService } from './utility/services/websocket.service';
import { LoaderService } from './shared/loader/loader.service';
import { HttpConfigInterceptor } from './utility/services/interceptor.service';
import { environment } from '../environments/environment';
// Sentry.init({
//   dsn: "https://cebbd999e78140af97b5d7ff77e9d6d1@o69967.ingest.sentry.io/6408419",
//   environment: environment.production ? 'production' : 'development',
// });
@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        LoaderComponent,
        NotificationComponent,
    ],
    imports: [BrowserModule, AppRoutingModule, SharedModule, HttpClientModule],
    providers: [
        DataService,
        WindowRef,
        ApiService,
        WebsocketService,
        LoaderService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpConfigInterceptor,
            multi: true,
        },
        // {
        //   provide: ErrorHandler,
        //   useValue: Sentry.createErrorHandler({
        //     showDialog: false,
        //   }),
        // }
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
