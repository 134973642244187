<div class="jw-modal flex">
  <div class="jw-modal-body" [ngStyle]="details?.body_styles">
    <div class="cross-btn" (click)="closeModal(id)">
      <app-icon [icon_name]="'cancel_notification'"></app-icon>
    </div>
    <div *ngIf="details?.heading" class="jw-modal-head">
      {{ details?.heading }}
    </div>
    <ng-content></ng-content>
    <div *ngIf="details?.confirm_btn" class="jw-modal-btn-row flex">
      <button (click)="close()" class="btn btn__cancel">Cancel</button>
      <button (click)="confirm()" class="btn btn__confirm">
        {{ this.details?.confirm_btn }}
      </button>
    </div>
  </div>
</div>
<div class="jw-modal-background"></div>
