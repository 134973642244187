import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchPipe',
})
export class SearchPipe implements PipeTransform {
  output: any;
  checkForExistence(obj: any, properties: any, q: string) {
    let bool = false;
    properties.forEach((prop_name: string) => {
      if (obj[prop_name]) {
        obj[prop_name] = obj[prop_name].toString();
        bool = bool || obj[prop_name].toLowerCase().includes(q);
      }
    });
    return bool;
  }
  transform(list: any, q: any, ...args: any[]): any[] {
    this.output = [];
    if (q && q.trim().length) {
      q = q.toLowerCase();
      if (args && args[0][0]) {
        for (let i = 0; i < list.length; i++) {
          let exists = this.checkForExistence(list[i], args[0], q);
          if (exists) {
            this.output.push(list[i]);
          }
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          if (list[i].toLowerCase().includes(q)) {
            this.output.push(list[i]);
          }
        }
      }
    } else {
      this.output = list;
    }
    return this.output;
  }
}
