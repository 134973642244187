import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sentencecase',
})
export class SentenceCasePipe implements PipeTransform {
  transform(value: any, type: any): any[] {
    if (value) {
      switch (type) {
        case 'remove_':
          value = value.replace(/_/g, ' ');
          break;
      }
      value = value.toLowerCase();
      return value.charAt(0).toUpperCase() + value.slice(1);
    } else {
      return value;
    }
  }
}
