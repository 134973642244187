export const status_list: any = {
    take_away_in_progress: [
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    take_away_food_ready: [
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'picked_up',
            name: 'Fulfilled',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    take_away_pre_orders: [
        {
            id: 'accepted',
            name: 'Pending',
        },
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    delivery_in_progress: [
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    delivery_food_ready: [
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'picked_up',
            name: 'Dispatched',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    delivery_completed: [
        {
            id: 'picked_up',
            name: 'Dispatched',
        },
        {
            id: 'delivered',
            name: 'Delivered',
        },
    ],
    delivery_pre_orders: [
        {
            id: 'accepted',
            name: 'Pending',
        },
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    dine_in_in_progress: [
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    dine_in_food_ready: [
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'picked_up',
            name: 'Completed',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    table_ordering_in_progress: [
        {
            id: 'accepted',
            name: 'Accepted',
        },
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    table_ordering_food_ready: [
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'served',
            name: 'Served',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    table_ordering_served: [
        {
            id: 'in kitchen',
            name: 'In kitchen',
        },
        {
            id: 'food_ready',
            name: 'Food ready',
        },
        {
            id: 'served',
            name: 'Served',
        },
        {
            id: 'cancelled',
            name: 'Cancel',
        },
    ],
    table_ordering_completed: [
        {
            id: 'partially_paid',
            name: 'Partially Paid',
        },
        {
            id: 'paid',
            name: 'Paid',
        },
    ],
};

export const lanes_config: any = {
    delivery: ['incoming', 'in_progress', 'food_ready', 'completed', 'cancelled'],
    take_away: ['incoming', 'in_progress', 'food_ready', 'completed', 'cancelled'],
    dine_in: ['incoming', 'in_progress', 'food_ready', 'completed', 'cancelled'],
    table_ordering: [
        'incoming',
        'in_progress',
        'food_ready',
        'served',
        'completed',
        'cancelled',
    ],
};
