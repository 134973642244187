// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  RAILS_API: 'https://massiveapi.hashtagloyalty.net',
  ONE_SIGNAL_APP_ID: 'f25e42ba-36d2-4dcf-9ee3-53dfa33fb2ad',
  // RAILS_API: 'https://app.thrivenow.in',
  MIXPANEL_ID: 'ff34ddcb25526f4a0ae5a9b3e9ab3d34',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
