<!-- order info modal -->
<div class="order_card" *ngIf="order_info && is_open">
    <span class="tag tag--blue" *ngIf="order_info?.pre_order">PRE-ORDER</span>
    <span
          class="tag tag--yellow"
          *ngIf="order_info?.order_status === 'partially_paid'">DUE</span>
    <span class="tag tag--green" *ngIf="order_info?.order_status === 'paid'">PAID</span>
    <div class="flex" style="align-items: center; justify-content: space-between;">
        <div class="card_heading" *ngIf="card_lane !== 'served'">
            Order #{{ order_info?.id }}
        </div>
        <div class="card_heading flex" *ngIf="card_lane === 'served'">
            Order #
            <select class="m__l--5" [disabled]="lane === 'completed' && (order_info?.delivery_type === '3rd party delivery' || order_info?.is_table_ordering)" (change)="changeOrderId($event.target.value)">
                <option
                        *ngFor="let option of order_groups"
                        [selected]="option === order_info?.order_id"
                        [disabled]="(option.id === 'picked_up' && order_info?.order_status !== 'food_ready' && lane === 'food_ready') || (order_info?.delivery_type === '3rd party delivery' && option.id === 'picked_up' && lane === 'food_ready')"
                        [value]="option">
                    {{ option }}
                </option>
            </select>
        </div>
        <div style="display: flex; gap: 10px;" class="mr-30">
            <div
                 *ngIf="card_lane === 'pre_order'"
                 class="btn-thrive_secondary"
                 (click)="updateOrderStatus('cancelled')">
                Cancel
            </div>
            <div
                 *ngIf="order_info?.order_status === 'pending' && show_status"
                 class="btn-thrive_secondary"
                 (click)="updateOrderStatus('cancelled')">
                Cancel
            </div>
            <div
                 *ngIf="order_info?.order_status === 'pending' && show_status"
                 class="btn-thrive_primary"
                 (click)="updateOrderStatus('accepted')">
                Accept
            </div>
        </div>
        <select
                class="m__l--20"
                [disabled]="
        (card_lane === 'completed' && order_opened?.is_table_ordering) ||
        (card_lane === 'completed' &&
          order_info?.order_summary?.delivery_type === '3rd party delivery' &&
          order_info?.order_summary?.type === 'delivery')
      "
                (change)="updateOrderStatus($event.target.value)"
                *ngIf="show_status && status_list[ordering_mode + '_' + card_lane]">
            <option
                    *ngFor="let option of status_list[ordering_mode + '_' + card_lane]"
                    [selected]="option.id === order_info?.order_status"
                    [disabled]="(option.id === 'picked_up' && order_info.order_status !== 'food_ready' && card_lane === 'food_ready') || (order_info?.order_summary?.delivery_type === '3rd party delivery' && option.id === 'picked_up')"
                    [value]="option.id">
                {{ option.name }}
            </option>
        </select>
    </div>
    <!-- top bar -->
    <div class="flex">
        <div class="assign_order" *ngIf="order_info?.order_time_in_minutes">
            Time left
            <div class="time" [ngClass]="'text--' + order_info?.color_status">
                {{ order_info?.order_time_in_minutes }}
            </div>
        </div>
        <div class="assign_order" *ngIf="order_info?.table_number">
            Table number
            <div class="time">
                {{ order_info?.table_number
                }}<app-icon
                          *ngIf="show_edit_icon.indexOf(order_info?.order_status) > -1"
                          [icon_name]="'edit'"
                          (click)="editTableNumber()"></app-icon>
            </div>
        </div>
        <div class="assign_order">
            Placed at
            <div class="time">{{ order_info?.placed_at }}</div>
        </div>
        <div class="assign_order" *ngIf="order_info?.order_type">
            Order Type
            <div class="time">{{ order_info?.order_type }}</div>
        </div>
        <div class="assign_order" *ngIf="order_info?.order_time">
            <span *ngIf="!order_info?.pre_order">{{
                order_info?.order_summary?.type === 'take_away'
                ? 'Pickup time'
                : 'Delivery time'
                }}</span>
            <span *ngIf="order_info?.pre_order">Scheduled time</span>
            <div class="time">
                {{ order_info?.order_time }}
                <app-icon
                          *ngIf="
            show_edit_icon.indexOf(order_info?.order_status) > -1 &&
            order_info?.order_summary?.type !== 'dine_in' &&
            merchant_info['pre_order_' + order_info?.order_summary?.type]
          "
                          (click)="editDeliveryTime()"
                          [icon_name]="'edit'"></app-icon>
            </div>
        </div>
        <div class="assign_order" *ngIf="order_info?.brand">
            Brand
            <div class="time">{{ order_info?.brand }}</div>
        </div>
        <div
             class="assign_order"
             *ngIf="order_info?.order_summary?.type === 'delivery'">
            Delivery type
            <div class="time flex">
                {{ order_info?.order_summary?.delivery_type }}
                <app-icon
                          *ngIf="
            show_edit_icon.indexOf(order_info?.order_status) > -1 &&
            edit_delivery_type &&
            show_status
          "
                          (click)="editOrderType('edit_delivery_type')"
                          [icon_name]="'edit'"></app-icon>
            </div>
        </div>
    </div>
    <!-- cards -->
    <div class="flex">
        <div class="flex_left">
            <div>
                <div class="flex space__between__h align__center">
                    <div class="inner_heading">Customer Details</div>
                    <div
                         class="btn__visit"
                         [ngStyle]="{
              'background-color':
                order_info?.customer_details['visit'] === 'Repeat Customer'
                  ? '#69d141'
                  : '#ff8600'
            }">
                        {{ order_info?.customer_details['visit'] }}
                    </div>
                </div>
                <div class="line"></div>
                <div class="flex card_content" *ngFor="let key of customer_info_keys">
                    <div *ngIf="key !== 'visit'">
                        {{ key | sentencecase : 'remove_' }}
                    </div>
                    <div
                         *ngIf="key !== 'visit'"
                         [innerHTML]="order_info?.customer_details[key]"></div>
                </div>
            </div>
            <div>
                <div class="flex space__between__h align__center">
                    <div class="inner_heading">Order Summary</div>
                    <div
                         class="flex space__between__h align__center source {{
              order_info.order_source
            }}">
                        <app-icon
                                  style="margin-right: 5px"
                                  [icon_name]="order_info.order_source"></app-icon>
                        <span>{{ order_info.order_source }}</span>
                    </div>
                </div>
                <div class="line"></div>
                <div class="flex card_content" *ngFor="let key of order_sum_keys">
                    <div>{{ key | sentencecase : 'remove_' }}</div>
                    <div>
                        <span
                              [ngClass]="{
                bottom_line:
                  order_info?.order_summary?.driver_assigned === 'Assign Now' &&
                  key === 'driver_assigned' &&
                  show_status
              }"
                              [innerHTML]="order_info?.order_summary[key]"></span>
                        <img
                             class="loader"
                             *ngIf="
                key === 'driver_assigned' &&
                order_info?.order_summary?.driver_assigned ===
                  'Please wait, updating…'
              "
                             src="./../../../assets/images/loader.gif"
                             alt="loader" />
                        <app-icon
                                  *ngIf="
                show_status &&
                show_edit_icon.indexOf(order_info?.order_status) > -1 &&
                key === 'driver_assigned' &&
                order_info?.order_summary?.driver_assigned &&
                order_info?.order_summary?.driver_assigned !==
                  'Please wait, updating…'
              "
                                  (click)="
                editOrderType(
                  order_info?.order_summary?.driver_assigned === 'Assign Now'
                    ? 'assign_now'
                    : 'reassign_driver'
                )
              "
                                  [icon_name]="'edit'">
                        </app-icon>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex_right print" #orderDetails>
            <div>
                <div class="inner_heading flex">
                    Order Details
                    <div *ngIf="!edit_mode_on" style="display: flex; gap: 10px;">
                        <button
                                class="btn-thrive_secondary"
                                *ngIf="
                show_edit_icon.indexOf(order_info?.order_status) > -1 &&
                order_info.can_edit_order
              "
                                (click)="editOrderEnable()">
                            Edit order
                        </button>
                        <button class="btn-thrive_secondary" (click)="print()">Print</button>
                    </div>
                    <div *ngIf="edit_mode_on" style="display: flex; gap: 10px;">
                        <button
                                class="btn-thrive_primary"
                                [ngClass]="{
                'btn--disabled avoid-clicks':
                  (0 < order_info?.order_details?.total_difference &&
                    !this.order_opened?.is_table_ordering) ||
                  order_info?.order_details?.item_total === 0
              }"
                                [ngClass]="{ 'btn--disabled avoid-clicks': !is_order_edit }"
                                (click)="edit(1)">
                            Confirm edit
                        </button>
                        <button class="btn-thrive_secondary" (click)="edit(0)">
                            Cancel edit
                        </button>
                    </div>
                </div>
                <div class="line"></div>
                <div class="flex table_cell">
                    <div
                         [ngClass]="{
              flex40: order_info?.brand && order_info?.brand === 'Multiple',
              flex60: !order_info?.brand || order_info?.brand !== 'Multiple'
            }">
                        Item
                    </div>
                    <div
                         class="flex20"
                         *ngIf="order_info?.brand && order_info?.brand === 'Multiple'">
                        Brand Name
                    </div>
                    <div class="flex18">Qty</div>
                    <div class="flex18">Total</div>
                    <div class="flex7"></div>
                </div>
                <div
                     class="flex table_cell"
                     *ngFor="let item of order_info?.order_details?.items">
                    <div
                         class="table_content"
                         [ngClass]="{
              flex40: order_info?.brand && order_info?.brand === 'Multiple',
              flex60: !order_info?.brand || order_info?.brand !== 'Multiple'
            }">
                        <div>{{ item?.name }}</div>
                        <div class="custom" *ngFor="let customization of item?.custom">
                            {{ customization.name }}
                        </div>
                    </div>
                    <div
                         class="table_content flex20"
                         *ngIf="order_info?.brand && order_info?.brand === 'Multiple'">
                        {{ item?.brand_name }}
                    </div>
                    <div class="table_content flex18">
                        <span *ngIf="edit_mode_on" class="btn" (click)="decreaseQty(item)">-</span>
                        {{ item?.quantity }}
                        <span *ngIf="edit_mode_on" class="btn" (click)="increaseQty(item)">+</span>
                    </div>
                    <div class="table_content flex18">₹{{ item?.total }}</div>
                    <div class="flex7">
                        <app-icon
                                  [id]="'remove_item'"
                                  *ngIf="edit_mode_on"
                                  (click)="deleteItem(item)"
                                  [icon_name]="'failure'"></app-icon>
                    </div>
                </div>
                <div *ngIf="edit_mode_on">
                    <span class="btn btn__add__item" (click)="addItem()">+Add an item</span>
                </div>
                <div class="bill_detail">
                    <div *ngIf="order_info?.order_details?.special_intructions">
                        <div class="cooking_heading">Special instructions</div>
                        <div class="cooking_card">
                            {{ order_info?.order_details?.special_intructions }}
                        </div>
                    </div>
                    <div class="line"></div>
                    <div class="flex bill_total">
                        <div>Item total</div>
                        <div>₹{{ order_info?.order_details?.item_total }}</div>
                    </div>
                    <div
                         class="flex bill_total red__font"
                         *ngIf="
              (order_info?.order_details?.discount_details | json) != '{}' &&
              !order_opened?.is_table_ordering
            ">
                        <div>
                            Total discount
                            <span style="font-size: 8px">(
                                {{
                                order_info?.order_details?.discount_details?.display_text
                                }}
                                )</span>
                        </div>
                        <div>
                            <span *ngIf="order_info?.order_details?.discount > 0">-</span>₹{{
                            order_info?.order_details?.discount
                            }}
                        </div>
                    </div>

                    <div
                         class="flex bill_total"
                         *ngFor="
              let charges of order_info?.order_details?.charge_details?.details
            ">
                        <div>{{ charges.name }}</div>
                        <div>₹{{ charges.value }}</div>
                    </div>

                    <div
                         class="flex bill_total"
                         *ngIf="order_info?.order_details?.tax_details?.total">
                        <div>
                            Total taxes
                            <app-icon
                                      [icon_name]="'info'"
                                      (click)="taxBreakdown()"></app-icon>
                        </div>
                        <div>₹{{ order_info?.order_details?.tax_details?.total }}</div>
                    </div>

                    <div class="flex bill_total" *ngIf="order_info?.order_details?.tip">
                        <div>Tip for your server</div>
                        <div>₹{{ order_info?.order_details?.tip }}</div>
                    </div>
                    <div class="flex bill_total" *ngIf="!order_opened?.is_table_ordering">
                        <div>Grand total</div>
                        <div>₹{{ order_info?.order_details?.grand_total }}</div>
                    </div>
                    <div
                         class="flex bill_total"
                         *ngIf="order_info?.order_details?.revised_total">
                        <div>Revised Grand total</div>
                        <div>
                            ₹{{ order_info?.order_details?.revised_total }}
                            <span
                                  class="green_font"
                                  *ngIf="order_info?.order_details?.total_difference !== 0"
                                  [ngClass]="{
                  revised_font: order_info?.order_details?.total_difference > 0
                }">
                                {{
                                order_info?.order_details?.total_difference.toFixed(2)
                                }}</span>
                        </div>
                    </div>
                    <div
                         class="flex revised_font"
                         *ngIf="
              order_info?.order_details?.total_difference &&
              !order_opened?.is_table_ordering
            ">
                        {{ order_info?.order_details?.message }}
                    </div>
                    <div class="flex revised_font" *ngIf="order_info?.not_editable">
                        {{ order_info?.not_editable }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- order info modal end -->
<app-skeleton-loader
                     *ngIf="!order_info"
                     [count]="'1'"
                     [type]="'box'"
                     [details]="{ styles: { height: '60vh', width: '100%' } }"></app-skeleton-loader>
<!-- date & time update -->
<jw-modal
          [details]="{
    heading:
      'Change ' +
      order_types_name[order_info?.order_summary?.type] +
      ' date & time',
    body_styles: change_time_styles
  }"
          id="deliveryDateTime">
    <form [formGroup]="editDateTimeForm" *ngIf="editDateTimeForm">
        <div class="flex flex_wrap">
            <div>
                <div class="inner_heading">
                    {{
                    order_types_name[order_info?.order_summary?.type]
                    | sentencecase : ''
                    }}
                    date
                </div>
                <select
                        class="select-box"
                        placholder="Choose date"
                        formControlName="pre_order_date"
                        (change)="onChangeDate($event.target.value)">
                    <option *ngFor="let date of delivery_date_time?.date" [value]="date">
                        {{ date }}
                    </option>
                </select>
                <div *ngIf="submitted && f.pre_order_date.errors" class="invalid-field">
                    <div *ngIf="f.pre_order_date.errors.required">Date is required.</div>
                </div>
            </div>
            <div>
                <div class="inner_heading">
                    {{
                    order_types_name[order_info?.order_summary?.type]
                    | sentencecase : ''
                    }}
                    time
                </div>
                <select
                        class="select-box"
                        placholder="Choose time"
                        formControlName="pre_order_time">
                    <option
                            *ngFor="let time of delivery_date_time?.time"
                            [value]="time.id">
                        {{ time.name }}
                    </option>
                </select>
                <div *ngIf="submitted && f.pre_order_time.errors" class="invalid-field">
                    <div *ngIf="f.pre_order_time.errors.required">Time is required.</div>
                </div>
            </div>
        </div>
        <div class="flex align_right">
            <button
                    [disabled]="delivery_date_time.clicked"
                    [ngClass]="{ 'btn--disabled': delivery_date_time.clicked }"
                    class="btn-thrive_primary"
                    appDebounceClick
                    (debounceClick)="updateDeliveryDateAndTime()"
                    [debounceTime]="700">
                Confirm
            </button>
        </div>
    </form>
</jw-modal>
<jw-modal id="addItems" [details]="{ body_styles: modal_styles['add_items'] }">
    <app-add-item
                  [is_item_open]="is_add_items_open"
                  [is_customize_open]="is_customize"
                  [order_info]="order_info"
                  [order_type]="order_info?.order_summary?.type"
                  (updateOrder)="updateOrderApi($event)"
                  (customize)="customizeEvent($event)"></app-add-item>
</jw-modal>
<jw-modal
          id="taxBreakdown"
          [details]="{ heading: 'Total taxes', body_styles: { width: '450px' } }">
    <div
         class="flex taxs_breakdown"
         *ngFor="let taxs of order_info?.order_details?.tax_details?.details">
        <div>{{ taxs.name }}</div>
        <div>₹{{ taxs.value }}</div>
    </div>
</jw-modal>
