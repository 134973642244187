<div *ngIf="!is_customize_open">
  <h3>Add New Item</h3>
  <div class="flex action__row">
    <div class="input__search flex">
      <input
        placeholder="Search by item name, category"
        [formControl]="search_item"
      />
      <app-icon class="icon-search" [icon_name]="'search'"></app-icon>
    </div>
    <select ngDefaultControl [(ngModel)]="selected_category">
      <option [value]="''">All</option>
      <option [value]="cat?.name" *ngFor="let cat of menu_details?.categories">
        {{ cat?.name }}
      </option>
    </select>
  </div>
  <div class="table__items">
    <div
      class="cat__row"
      *ngFor="
        let cat of menu_details?.categories
          | searchPipe : selected_category : ['name'];
        let i = index
      "
    >
      <h4 *ngIf="!search_item.value?.length">{{ cat?.name }}</h4>
      <!-- items in category -->
      <div *ngIf="cat?.items?.length">
        <div
          class="flex item__row"
          *ngFor="
            let item of cat?.items
              | searchPipe : search_item.value : ['name', 'pos_category_name']
          "
        >
          <div>{{ menu_details.items[item.pos_item_id]?.name }}</div>
          <div>₹{{ menu_details.items[item.pos_item_id]?.item_price }}</div>
          <div class="text__center">
            <button
              class="btn btn__controls"
              (click)="addNewItem(menu_details.items[item.pos_item_id])"
            >
              Add
            </button>
            <div
              *ngIf="
                menu_details.items[item.pos_item_id]?.addons?.length ||
                menu_details.items[item.pos_item_id]?.variations?.length ||
                menu_details.items[item.pos_item_id]?.combos?.length
              "
              class="customizable"
            >
              Customizable
            </div>
          </div>
        </div>
        <!-- <div *ngIf="(cat?.items | searchPipe:search_item.value:['name','pos_category_name'])?.length === 0 && cat?.items?.length > 0 && i==0">
          <div class="no_data_found">There is no data matching your search.</div>
        </div> -->
      </div>
      <!-- sub category  -->
      <div *ngIf="!cat?.items?.length">
        <div *ngFor="let subcat of cat?.sub_category">
          <div
            *ngIf="
              (
                subcat?.items
                | searchPipe : search_item.value : ['name', 'pos_category_name']
              )?.length > 0
            "
          >
            <h4 class="sub_category">{{ subcat.name }}</h4>
            <p *ngIf="subcat.description">{{ subcat.description }}</p>
          </div>
          <div
            class="flex item__row"
            *ngFor="
              let item of subcat?.items
                | searchPipe : search_item.value : ['name', 'pos_category_name']
            "
          >
            <div>{{ menu_details.items[item.pos_item_id]?.name }}</div>
            <div>₹{{ menu_details.items[item.pos_item_id]?.item_price }}</div>
            <div class="text__center">
              <button
                class="btn btn__controls"
                (click)="addNewItem(menu_details.items[item.pos_item_id])"
              >
                Add
              </button>
              <div
                *ngIf="
                  menu_details.items[item.pos_item_id]?.addons?.length ||
                  menu_details.items[item.pos_item_id]?.variations?.length ||
                  menu_details.items[item.pos_item_id]?.combos?.length
                "
                class="customizable"
              >
                Customizable
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="is_customize_open">
  <div class="flex">
    <app-icon
      [icon_name]="'back'"
      (click)="is_customize_open = false"
    ></app-icon>
    <div class="card_heading">Add Item Details</div>
  </div>
  <div>
    <h4 class="headline__big">
      {{ selected_item?.name }}
    </h4>
    <div class="item__desc">
      <p>
        {{ selected_item?.description }}
      </p>
      <div class="price">
        ₹{{ selected_item?.item_price | number : '1.0-2' }}
      </div>
    </div>
  </div>
  <div>
    <!-- variations -->
    <div *ngFor="let variation of selected_item?.variations; let i = index">
      <div class="menu-size">
        {{ variation?.name }}
      </div>
      <div
        class="attribute-row flex"
        *ngFor="let item of variation?.items"
        [ngClass]="{
          checked: variation['selected_variation'] === item.variation_id
        }"
      >
        <label class="custom__radio" *ngIf="!selected_item?.show_only">
          {{ item.name | titlecase }}
          <input
            type="radio"
            [id]="item.variation_id"
            [value]="item.variation_id"
            name="var__{{ i }}"
            [(ngModel)]="variation['selected_variation']"
          />
          <span class="checkmark"></span>
        </label>
        <div *ngIf="selected_item?.show_only">
          {{ item.name | titlecase }}
        </div>
        <div>₹{{ item?.display_price }}</div>
      </div>
    </div>
    <!-- add ons -->
    <div *ngFor="let addon of selected_item?.addons; let i = index">
      <div class="menu-size">
        {{ addon?.name }}
      </div>
      <p class="content__helper" *ngIf="addon?.max > 1">
        Choose upto {{ addon?.max }} options
        <span *ngIf="addon?.min > 0"
          >( minimum {{ addon?.min }} required )</span
        >
      </p>
      <p class="content__helper" *ngIf="addon?.max === 1">
        Choose any 1 option <span *ngIf="addon?.min > 0">( required )</span>
      </p>
      <div
        class="attribute-row flex"
        *ngFor="let item of addon?.items"
        [ngClass]="{
          disable: addon['count'] >= addon.max && !item['checked'],
          checked: item['checked']
        }"
      >
        <label class="custom__checkbox" *ngIf="!selected_item?.show_only">
          <input
            type="checkbox"
            [disabled]="addon['count'] >= addon.max && !item['checked']"
            (change)="validateAddOn($event, addon)"
            [(ngModel)]="item['checked']"
          />
          {{ item.name }}
          <span class="checkmark"></span>
        </label>
        <div *ngIf="selected_item?.show_only">
          {{ item.name }}
        </div>
        <div>₹{{ item?.display_price | number : '1.0-2' }}</div>
      </div>
    </div>
    <!-- combos -->
    <div *ngFor="let combo of selected_item?.combos; let i = index">
      <div class="menu-size">
        {{ combo?.name }}
      </div>
      <p class="content__helper" *ngIf="combo?.max > 1">
        Choose upto {{ combo?.max }} options
        <span *ngIf="combo?.min > 0"
          >( minimum {{ combo?.min }} required )</span
        >
      </p>
      <p class="content__helper" *ngIf="combo?.max === 1">
        Choose any 1 option <span *ngIf="combo?.min > 0">( required )</span>
      </p>
      <div
        class="attribute-row flex"
        *ngFor="let item of combo?.items"
        [ngClass]="{
          disable: combo['count'] >= combo.max && !item['checked'],
          checked: item['checked']
        }"
      >
        <label class="custom__checkbox" *ngIf="!selected_item?.show_only">
          <input
            type="checkbox"
            [disabled]="combo['count'] >= combo.max && !item['checked']"
            (change)="validateAddOn($event, combo)"
            [(ngModel)]="item['checked']"
          />
          {{ item.name }}
          <span class="checkmark"></span>
        </label>
        <div *ngIf="selected_item?.show_only">
          {{ item.name }}
        </div>
        <div>₹{{ item?.display_price | number : '1.0-2' }}</div>
      </div>
    </div>
  </div>
  <div class="flex add-to-cart-bottom" *ngIf="!selected_item?.show_only">
    <div
      class="card-btn"
      [ngStyle]="
        selected_item?.reward_customization && { width: '100%', margin: '10px' }
      "
      [ngClass]="{ disable: isAddonsInvalid() }"
      (click)="addItemWithCustom(selected_item)"
    >
      Add
    </div>
  </div>
</div>
