import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
export interface Message {
  source: string;
  content: string;
}

@Injectable()
export class WebsocketService {
  private subject: AnonymousSubject<MessageEvent>;
  public messages: Subject<Message>;
  ws: any = null;
  constructor() {}

  public initialiseWebsocket(url: string) {
    this.messages = <Subject<Message>>this.connect(url).pipe(
      map((response: MessageEvent): Message => {
        let data = JSON.parse(response.data);
        if (
          data.message &&
          typeof data.message === 'object' &&
          data.message.new_orders
        ) {
          return data;
        } else {
          return;
        }
      })
    );
  }
  public connect(url: string): AnonymousSubject<MessageEvent> {
    if (!this.subject) {
      this.subject = this.create(url);
    }
    return this.subject;
  }
  public closeWebsocket() {
    this.subject = null;
    this.messages = null;
  }
  private create(url: string): AnonymousSubject<MessageEvent> {
    if (this.ws) {
      this.ws.close();
    }
    this.ws = new WebSocket(url);
    this.ws.onopen = (event) => {
      let params: any = {
        command: 'subscribe',
        identifier: JSON.stringify({ channel: 'OrdersListChannel' }),
      };
      this.ws.send(JSON.stringify(params));
    };
    let observable = new Observable((obs: Observer<MessageEvent>) => {
      this.ws.onmessage = obs.next.bind(obs);
      this.ws.onerror = obs.error.bind(obs);
      this.ws.onclose = obs.complete.bind(obs);
      return this.ws.close.bind(this.ws);
    });
    let observer = {
      error: null,
      complete: null,
      next: (data: Object) => {
        if (this.ws.readyState === WebSocket.OPEN) {
          this.ws.send(JSON.stringify(data));
        }
      },
    };
    return new AnonymousSubject<MessageEvent>(observer, observable);
  }
}
