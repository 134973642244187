import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class DataService {
  // private data = new BehaviorSubject('');
  // currentData = this.data.asObservable();
  private merchantData: BehaviorSubject<any> = new BehaviorSubject({});
  merchantDataReceived: Observable<any> = this.merchantData.asObservable();
  private loggedInStatus = new BehaviorSubject('');
  isLoggedIn = this.loggedInStatus.asObservable();
  private list_of_orders = new BehaviorSubject('');
  refresh_orders = this.list_of_orders.asObservable();
  private localStorageUpdated = new BehaviorSubject('');
  refresh_variable = this.localStorageUpdated.asObservable();
  status: any;
  constructor() {}

  setData(key: string, value: any) {
    this[key].next(value);
  }

  //set data to local storage
  setDataInStore(key: string, value: any) {
    let data = btoa(unescape(encodeURIComponent(JSON.stringify(value))));
    localStorage.setItem(key, data);
  }

  //get data from local storage
  getDataFromStore(key: string) {
    if (localStorage.getItem(key)) {
      try {
        let data = decodeURIComponent(
          escape(window.atob(localStorage.getItem(key)))
        );
        return JSON.parse(data);
      } catch (e) {
        localStorage.clear();
        return false;
      }
    } else {
      return false;
    }
  }

  //remove particular data from local storage
  removeDataFromStore(key: string) {
    // console.log('inside remove');
    if (localStorage) {
      localStorage.removeItem(key);
    }
  }

  //clear local storage
  clearDataInStore() {
    localStorage.clear();
  }
}
