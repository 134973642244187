import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './utility/guard/auth.guard';
import { LoginGuard } from './utility/guard/login.guard';
import { MainComponent } from './shared/main/main.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [LoginGuard],
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'login/:auth',
    // canActivate: [LoginGuard],
    loadChildren: () =>
      import('./features/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'forgot-password',
    // canActivate: [LoginGuard],
    loadChildren: () =>
      import('./features/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'user/setup',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./features/user-setup/user-setup.module').then(
        (m) => m.UserSetupModule
      ),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: MainComponent,
    children: [
      {
        path: 'active-orders',
        loadChildren: () =>
          import('./features/active-orders/active-orders.module').then(
            (m) => m.ActiveOrdersModule
          ),
      },
      {
        path: 'recent-orders',
        loadChildren: () =>
          import('./features/recent-orders/recent-orders.module').then(
            (m) => m.RecentOrdersModule
          ),
      },
      {
        path: 'future-orders',
        loadChildren: () =>
          import('./features/future-order/future-order.module').then(
            (m) => m.FutureOrderModule
          ),
      },
      {
        path: 'inventory',
        loadChildren: () =>
          import('./features/inventory/inventory.module').then(
            (m) => m.InventoryModule
          ),
      },
      {
        path: 'settings',
        loadChildren: () =>
          import('./features/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
      },
      {
        path: '',
        redirectTo: 'active-orders',
        pathMatch: 'full',
      },
      {
        path: '**',
        redirectTo: 'active-orders',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
