import {
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ApiService } from '../../../utility/services/api.service';
import { DataService } from '../../../utility/services/data-sharing.service';
import { ModalService } from '../../../shared/modal';
import { MixpanelService } from '../../../utility/services/mixpanel.service';
@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss'],
})
export class AddItemComponent implements OnChanges {
  @Input() order_type: string;
  @Input() modal_id: string;
  @Input() order_info: any;
  @Input() is_item_open: boolean;
  @Input() is_customize_open: boolean;
  @Output() updateOrder = new EventEmitter<string>();
  @Output() customize = new EventEmitter<boolean>();
  merchant_info: any;
  menu_details: any;
  selected_category: string = '';
  selected_item: any = null;
  search_item = new UntypedFormControl('');
  constructor(
    private apiService: ApiService,
    private dataService: DataService,
    private modalService: ModalService,
    private mixpanelService: MixpanelService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.is_item_open) {
      this.initialise();
    }
  }

  //initilaise
  initialise() {
    this.merchant_info = this.dataService.getDataFromStore('merchant_info');
    this.getMenuItems();
  }

  //function to get menu items
  getMenuItems() {
    let params = { ordering_type: this.order_type };
    this.apiService
      .getData(
        '/contactless_dining/api/v1/merchants/' +
          this.merchant_info.slug +
          '/menu',
        params
      )
      .subscribe(
        (result: any) => {
          this.menu_details = result;
        },
        (err: any) => {}
      );
  }

  //add new items
  addNewItem(item: any) {
    if (
      (item.addons && item.addons.length) ||
      (item.variations && item.variations.length) ||
      (item.combos && item.combos.length)
    ) {
      this.customize.next(true);
      this.selected_item = item;
    } else {
      let id: string = this.modal_id ? this.modal_id : 'addItems';
      this.modalService.close(id);
      this.updateOrder.next(item);
    }
    let mixpanel_item_obj: any = {
      order_id: this.order_info.id,
      item_id: item.pos_item_id,
      item_name: item.name,
      item_price: item.item_price,
      item_category: item.pos_category_name,
      item_quantity: 1,
      source: 'Active orders page',
    };
    this.mixpanelService.trackEvents('add_items', mixpanel_item_obj);
  }
  addItemWithCustom(item) {
    let id: string = this.modal_id ? this.modal_id : 'addItems';
    this.modalService.close(id);
    this.updateOrder.next(item);
  }
  //validate addon
  validateAddOn(event, addon) {
    if (event.target.checked) {
      addon['count'] = addon['count'] > -1 ? addon['count'] + 1 : 1;
    } else {
      addon['count'] -= 1;
    }
  }
  //  addon and variation validation
  isAddonsInvalid() {
    if (this.selected_item.addons && this.selected_item.addons.length) {
      let i: number;
      let status = false;
      for (i = 0; i < this.selected_item.addons.length; i++) {
        if (
          this.selected_item.addons[i].min &&
          (!this.selected_item.addons[i].count ||
            this.selected_item.addons[i].count <
              this.selected_item.addons[i].min)
        ) {
          status = true;
        }
      }
      return status;
    } else if (this.selected_item.combos && this.selected_item.combos.length) {
      let i: number;
      let status = false;
      for (i = 0; i < this.selected_item.combos.length; i++) {
        if (
          this.selected_item.combos[i].min &&
          (!this.selected_item.combos[i].count ||
            this.selected_item.combos[i].count <
              this.selected_item.combos[i].min)
        ) {
          status = true;
        }
      }
      return status;
    } else {
      return false;
    }
  }
}
