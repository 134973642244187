import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpParameterCodec,
  HttpParams,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DataService } from './data-sharing.service';
import { LoaderService } from '../../shared/loader/loader.service';
import { environment } from '../../../environments/environment';
@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  constructor(
    private dataService: DataService,
    private loaderService: LoaderService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: string = '';
    this.loaderService.show();
    if (
      request.url !==
      environment.RAILS_API + '/contactless_dining/api/v1/merchants/login'
    ) {
      if (this.dataService.getDataFromStore('staff_auth_token')) {
        token = this.dataService.getDataFromStore('staff_auth_token');
        if (token.length) {
          request = request.clone({
            headers: request.headers.set('Authorization', token),
          });
        }
      }
    }
    const params = new HttpParams({
      encoder: new CustomEncoder(),
      fromString: request.params.toString(),
    });
    request = request.clone({ params });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.loaderService.hide();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        this.loaderService.hide();
        return throwError(error);
      })
    );
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
