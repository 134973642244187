import {
  Component,
  ViewEncapsulation,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  Output,
  EventEmitter,
} from '@angular/core';
import { ModalService } from './modal.service';
@Component({
  selector: 'jw-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent implements OnInit {
  @Input() id: string;
  @Input() details: any;
  @Output() onClick = new EventEmitter<string>();
  private element: any;

  constructor(
    private modalService: ModalService,
    // @Inject(DOCUMENT) private document: Document,
    private el: ElementRef
  ) {
    this.element = el.nativeElement;
  }
  ngOnInit(): void {
    if (!this.id) {
      console.error('modal must have an id');
      return;
    }

    // move element to bottom of page (just before </body>) so it can be displayed above everything else
    document.body.appendChild(this.element);

    // close modal on background click
    this.element.addEventListener('click', (el) => {
      if (el.target.className === 'jw-modal') {
        this.close();
      }
    });

    // add self (this modal instance) to the modal service so it's accessible from controllers
    this.modalService.add(this);
  }

  // remove self from modal service when component is destroyed
  ngOnDestroy(): void {
    this.modalService.remove(this.id);
    this.element.remove();
  }

  // open modal
  open(): void {
    this.element.style.display = 'block';
    document.body.classList.add('jw-modal-open');
  }

  // close modal
  close(): void {
    this.element.style.display = 'none';
    document.body.classList.remove('jw-modal-open');
  }
  closeModal(id: string) {
    if (id !== 'addNewOrderDelPickModal') {
      this.modalService.close(id);
      this.onClick.next('close');
    } else {
      this.onClick.next('close');
    }
  }
  //on clicking on confirm
  confirm() {
    this.onClick.next('');
  }
}
