import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { DirectivesModule } from '../utility/directives/directives.module';
// import { ModalModule } from '../shared/modal/modal.module';
//components
import { SkeletonLoaderComponent } from './skeleton-loader/skeleton-loader.component';
import { IconComponent } from './icon/icon.component';
import { OrderComponent } from '../shared/order/order.component';
import { AddItemComponent } from '../features/active-orders/add-item/add-item.component';
import { ModalComponent } from '../shared/modal/modal.component';
import { SearchPipe } from './../utility/pipes/search.pipe';
//pipes
import { SentenceCasePipe } from '../utility/pipes/sentence-case.pipe';
const declarations_arr: any = [
  SkeletonLoaderComponent,
  IconComponent,
  OrderComponent,
  AddItemComponent,
  ModalComponent,
  SentenceCasePipe,
  SearchPipe,
];
const imports_arr: any = [
  CommonModule,
  // ModalModule,
  FormsModule,
  ReactiveFormsModule,
  NgxSkeletonLoaderModule,
  DirectivesModule,
];
const exports_arr: any = declarations_arr.concat(imports_arr);
@NgModule({
  declarations: declarations_arr,
  imports: imports_arr,
  exports: exports_arr,
  providers: [],
})
export class SharedModule {}
