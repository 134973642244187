import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//directives
import { DebounceClickDirective } from './debounce-click.directive';
import { ClickOutsideDirective } from './click-outside.directive';
//arrays
const declarations_arr: any = [DebounceClickDirective, ClickOutsideDirective];
const imports_arr: any = [CommonModule];
const exports_arr: any = declarations_arr;

@NgModule({
  declarations: declarations_arr,
  imports: imports_arr,
  exports: exports_arr,
  providers: [],
})
export class DirectivesModule {
  constructor() {
    // console.log('directive module loaded');
  }
}
