import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class ApiService {
  base_url: string = environment.RAILS_API;
  constructor(private http: HttpClient) {}

  getData(api: string, params: any) {
    return params
      ? this.http.get(this.base_url + api, { params })
      : this.http.get(this.base_url + api);
  }

  getDataFromExt(api: string, params: any) {
    return params ? this.http.get(api, { params }) : this.http.get(api);
  }

  postData(api: string, data: any) {
    return this.http.post(this.base_url + api, data);
  }

  putData(api: string, data: any) {
    return this.http.put(this.base_url + api, data);
  }

  deleteData(api: string, params: any) {
    return params
      ? this.http.delete(this.base_url + api, { params })
      : this.http.delete(this.base_url + api);
  }
}
