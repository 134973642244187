import { Component, OnInit } from '@angular/core';
import { DataService } from './utility/services/data-sharing.service';
import { Router, NavigationEnd } from '@angular/router';
import { MixpanelService } from './utility/services/mixpanel.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  path: any;
  current_url: any;
  previous_url: any;
  constructor(
    private dataService: DataService,
    private router: Router,
    private mixpanelService: MixpanelService
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.path = event.urlAfterRedirects;
        this.previous_url = this.current_url;
        this.current_url = event.url;
        if (!this.path.includes('/login/')) {
          if (this.dataService.getDataFromStore('staff_auth_token')) {
            let merchant_info =this.dataService.getDataFromStore('merchant_info');
            if (merchant_info && !merchant_info.staff_email) {
              this.router.navigate(['/user/setup']);
            }
          }
        }
        this.mixpanelService.trackEvents('page_view', {
          url: event.urlAfterRedirects,
          source_url: this.previous_url,
        });
      }
    });
  }
  ngOnInit(): void {}
}
