<div id="notification" class="noti_area" *ngIf="is_open">
  <div class="heading">Notifications</div>
  <app-icon
    class="cross_button"
    (click)="closeNotification()"
    [icon_name]="'cancel_notification'"
  ></app-icon>
  <div class="saperator_line"></div>
  <div class="realtime_noti">
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
    <div class="numberof_noti">
      <div class="content_noti">
        You have an order! <b>Order #875</b> just came in.
      </div>
      <div class="time_noti">A few moments ago</div>
    </div>
  </div>
</div>
